@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;700&display=swap');

* {
  font-family: 'Playfair Display', serif;
  text-decoration: none;
}

.arrow-btn-left, .arrow-btn-right {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00B0E7;
  border: none;
  border-radius: 12px;
  color:#000000;
  width: 4vw;
  height: 8vh;
}

.arrow-img-left, .arrow-img-right {
  width: 3.5vw;
}

.arrow-img-left {
  transform: rotate(180deg);
}

.App {
  display: flex;
  flex-direction: column;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

.navbar {
  position: sticky;
  width: 100%;
  height: 16vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  top: 0;
  z-index: 999;
  background-color: white;
}

#logo {
  width: 10vw;
  height: 12vh;
  object-fit: cover;
  object-position: center;
  margin-left: 1.5em;
  border-radius: 12px;
}

.section-wrapper {
  display: flex;
  width: auto;
  align-items: center;
  gap: 5vw;
}

.section-wrapper > * {
  font-size: 1.5em;
  font-weight: 500;
}

.nav-button:after {
  color:#00B0E7;
  display:block;
  content: '';
  border-bottom: solid 3px #00B0E7;  
  transform: scaleX(0);  
  transition: transform 250ms ease-in-out;
}

.nav-button:hover:after {
  transform: scaleX(1);
}

.phone {
  margin-right: 3vw;
  transition: 0.3s ease-out;
}

.phone:hover {
  transform: scale(1.1);
}

.phone > * {
  font-size: 1.5em;
  font-weight: 500;
}

#phone-icon {
  width: 5vh;
  height: 5vh;
}

#phone-holder {
  display: flex;
  align-items: center;
  justify-content: center;
}

.home {
  width: 100vw;
  height: auto;
  position: relative;
  text-align: center;
}

.home-content {
  width: 100vw;
  height: 90vh;
  background-image: url('./files/background.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

#background {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.home-main {
  margin-top: 1em;
  color: #173753;
  font-size: 2em;
}

.home-short {
  color: #00B0E7;
  font-size: 6em;
}

.home-address-street, .home-address-town {
  color: #173753;
  font-size: 3em;
  font-weight: bold;
}

.home-check-more {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00B0E7;
  width: 18vw;
  height: 10vh;
  border-radius: 10px;
  font-size: 1.5em;
  font-weight: bold;
  color: #173753;
  cursor: pointer;
  transition: 0.3s ease-out;
}

.home-check-more:hover {
  transform: scale(1.1);
}

.about-me {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100vh;
}

.about-me-main {
  color: #00B0E7;
  font-size: 4em;
  font-weight: bold;
}

.about-me-text {
  font-size: 2em;
}

#portrait {
  object-fit: cover;
  object-position: top;
  width: 400px;
  height: 400px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px 0px rgba(66, 68, 90, 1);
  margin-left: 4vw;
}

.about-me-wrapper {
  width: 60vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.expirience {
  height: 90vh;
  background-color: #00B0E7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.expirience-title {
  font-size: 4em;
  color: white;
  font-weight: bold;
  text-align: center;
}

.expirience-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px;
}

.expirience-wrapper {
  padding: 3em;
  width: 20vw;
  display: flex;
  align-items: center;
  gap: 2vw;
}

.expirience-text {
  font-size: 1.4em;
  color: white;
}

.reviews {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 8vh;
  margin-bottom: 8vh;
}

.reviews-head {
  text-align: center;
  color:#00B0E7;
  font-size: 4em;
  font-weight: 600;
  margin-top: 4vh;
  margin-bottom: 3vh;
}

.reviews-desc {
  font-size: 2em;
  font-weight: 200;
}

.reviews-slider {
  width: 80vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 6vh;
}

.review-tile {
  color: white;
  background-color: #173753;
  width: 40vw;
  height: 30vh;
  border-radius: 12px;
  box-shadow: -8px 12px 6px 0px rgba(0, 0, 0, 0.35);
  padding: 1em;
}

.star-img {
  width: 2vw;
}

.review-tile-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 2em;
  border-bottom: 3px white solid;
}

.review-tile-desc {
  padding: 0.5vh;
  margin-top: 2vh;
  font-weight: 300;
  font-size: 1.5em;
}

.contact {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4em;
}

.contact-title {
  font-size: 4em;
  color: #00B0E7;
  font-weight: bold;
  text-align: center;
  padding-top: 1em;
}

.contact-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2vw;
}

.contact-info {
  display: flex;
  flex-direction: column;
  gap: 6vh;
}

.contact-info > * {
  font-size: 2em;
  color:#173753;
  font-weight: bold;
  text-decoration: none;
}

.contact-additional-info {
  color:#00B0E7;
  text-align: center;
  font-size: 2em;
  padding: 1em;
  padding-bottom: 1.5em;
}

.footer {
  width: 100%;
  height: 35vh;
  background-color: #173753;
  display: flex;
  align-items: center;
  gap: 10vw;
}

#logo-footer {
  margin-left: 4vw;
  width: 250px;
  height: 250px;
}

.footer-contact-title {
  color: #00B0E7;
  font-size: 2.5em;
  font-weight: bold;
}

.footer-line {
  display: none;
}

.footer-contact {
  display: flex;
  flex-direction: column;
  gap: 2vh;
}

.footer-contact > * {
  text-align: left;
}

.footer-contact-content > * {
  color: white;
  font-size: 1.5em;
}

.footer-socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6vh;
}

.footer-socials-facebook, .footer-socials-google {
  width: 30vw;
  display: flex;
  align-items: center;
  gap: 2vw;
  color: white;
  text-decoration: none;
  cursor: pointer;
  font-size: 1.6em;
  font-weight: bold;
}

#facebook-logo, #google-logo {
  width: 58px;
  height: 58px;
}

@media only screen and (max-width: 1000px) {

  .navbar {
    height: 12vh;
    justify-content: space-between;
  }

  .home, .about-me, .expirience, .contact {
    margin-bottom: 4vh;
    height: auto;
  }

  .section-wrapper {
    display: none;
    width: 0;
  }

  #logo {
    width: 30vw;
    height: 8vh;
    margin-top: 1vh;
  }

  .home-content {
    background-image: url('./files/background-mobile.png');
  }

  .home-main {
    font-size: 2em;
  }
  
  .home-short {
    font-size: 5em;
  }

  .home-address-street, .home-address-town {
    font-size: 2em;
  }

  .home-check-more {
    width: 50vw;
  }

  .about-me {
    flex-direction: column;
    justify-content: center;
    gap: 5vh;
  }

  #portrait {
    width: 250px;
    height: 250px;
    margin: 0;
  }

  .about-me-wrapper {
    width: 90vw;
    align-items: center;
    gap: 3vh;
  }

  .about-me-text {
    font-size: 1em;
    width: 90vw;
  }

  .expirience {
    height: auto;
  }

  .expirience-title {
    font-size: 3em;
    margin-top: 4vh;
  }

  .expirience-content {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 0;
  }

  .expirience-wrapper {
    width: 80vw;
    padding: 1em;
  }

  .arrow-btn-left, .arrow-btn-right {
    width: 10vw;
  }

  .reviews-head {
    font-size: 3em;
  }

  .reviews-desc {
    font-size: 1.2em;
    padding: 1em;
    text-align: center;
  }

  .reviews-slider {
    width: 90vw;
  }

  .review-tile {
    width: 55vw;
  }

  .review-tile-head {
    font-size: 1.3em;
  }

  .star-img {
    width: 6vw;
  }

  .review-tile-desc {
    font-size: 1em;
  }

  .contact {
    height: auto;
    gap: 2em;
  }

  .contact-title {
    font-size: 3em;
    padding-top: 0;
  }

  .contact-wrapper {
    flex-direction: column;
  }

  #google-map {
    width: 300px;
  }

  .contact-info {
    gap: 2vh;
    margin-top: 4vh;
  }

  .contact-info > * {
    font-size: 1.5em;
    text-align: center;
  }

  .contact-additional-info {
    font-size: 1em;
  }

  .footer {
    height: auto;
    flex-direction: column-reverse;
    gap: 2vh;
  }

  #logo-footer {
    object-fit: cover;
    object-position: center;
    width: 250px;
    height: 120px;
    border-radius: 12px;
    margin: 1em;
  }

  .footer-line {
    display: block;
    width: 90%;
    height: 1px;
    border-bottom: 2px solid grey;
  }

  .footer-contact {
    display: none;
  }


  .footer-socials {
    width: 100vw;
    gap: 2vh;
    padding-top: 2vh;
  }

  .footer-socials-facebook, .footer-socials-google {
    align-items: center;
    justify-content: center;
    font-size: 1.2em;
    width: 80vw;
  }
}